import React from "react";
import styled from "styled-components";
import GigFixLogo from "../dashboard/gigfix.png";
import { device } from "./device";

function DeleteAccount() {
  return (
    <MainWrapper>
      <Form>
        <MainTitle>Delete account</MainTitle>
        <DescriptionChanged>
          To delete your account, visit your Profile --{">"} Settings --{">"}{" "}
          then click "Delete Account" in the app, or email hello@gigfix.com.
        </DescriptionChanged>
        <Logo src={GigFixLogo} alt="GigFix Logo" />
      </Form>
    </MainWrapper>
  );
}

const MainWrapper = styled.div`
  background-color: #f2f2f4;
  padding: 55px 35px 45px 35px;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.desktop} {
    height: 100vh;
    width: 100%;
  }
`;

const Form = styled.form`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 900px) {
    height: 100vh;
    width: 25%;
  }
`;

const DescriptionChanged = styled.h1`
  margin-top: 240px;
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 14px;
  text-align: center;

  @media screen and (max-width: 1650px) {
    margin-top: 200px;
  }

  @media screen and (max-width: 400px) {
    margin-top: 150px;
  }
`;

const MainTitle = styled.h1`
  margin-bottom: 35px;
  font-weight: 300;
  font-size: 19px;
`;

const Logo = styled.img`
  height: 60px;
  width: 150px;
`;

export default DeleteAccount;
